export default {
  /**
   * this.$v is the instance of Vuelidate plugin
   * this.$t is the instance of i18n plugin
   */

  methods: {
    /**
     * Find the first form control with error and focus on it
     */
    focusOnValidationError() {
      // Find all available keys
      const vKeys = Object.keys(this.$v.form);
      /* eslint-disable-next-line */
      for (const formControl of vKeys) {
        // If the control has a `$` just break
        if (formControl.includes('$')) break;

        // If not, focus on the first found control with error status and focus on it
        if (this.$v.form[formControl].$error) {
          if (this.$refs[formControl].$refs?.input) {
            this.$refs[formControl].$refs.input.focus();
          } else {
            this.$refs[formControl].focus();
          }

          break;
        }
      }
    },

    /**
     * Get error messages for a given form control
     *
     * @param   {string} fieldName
     * @return  {Array} errorMessages
     */
    getErrorMessage(fieldName) {
      const field = this.$v.form[fieldName];
      const errorField = this._getShownErrorMessageField();
      let errorMessage = '';

      if (field) {
        const fieldKeys = Object.keys(field);
        /* eslint-disable-next-line */
        for (const rule of fieldKeys) {
          const ruleValue = field[rule];
          // If the control rule has a `$` just break
          if (rule.includes('$')) break;

          // If not - check if it's an error and show the message
          if (!ruleValue && field.$error && (!errorField || errorField === fieldName)) {
            errorMessage = this._getErrorMessageByRuleKey(rule);
          }
        }
      }

      return errorMessage;
    },

    /**
     * Get error string for the given validation rule
     *
     * @param   {string} key
     * @return  {string}
     */
    _getErrorMessageByRuleKey(key) {
      const errorsVocabulary = {
        required: this.$t('errorMessages.fillInTheField'),
        email: this.$t('errorMessages.invalidEMail'),
        passwordMatch: this.$t('errorMessages.passwordMustContain'),
        sameAsPassword: this.$t('errorMessages.passwordMismatch'),
      };

      return errorsVocabulary[key] ?? '';
    },

    /**
     * Get the name of the control with an error message shown
     *
     * @return {string} fieldName
     */
    _getShownErrorMessageField() {
      let fieldName = '';

      // Find all available keys
      const vKeys = Object.keys(this.$v.form);
      /* eslint-disable-next-line */
      for (const formControl of vKeys) {
        // If the control has a `$` just break
        if (formControl.includes('$')) break;

        // Determine and save the name of the control with an error
        if (this.$v.form[formControl].$error) {
          fieldName = formControl;
          break;
        }
      }

      return fieldName;
    },
  },
};
